.slick-track {
    display: flex;
    align-items: stretch;

    & .slick-slide {
        height: unset;

         & > div, & > div > div {
             height: 93%;
         }
    }
}

