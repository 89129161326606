@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Blue base theme */
  --primary-color: #376CA9;
  --primary-color-light: #84ABD7;
  --light-color: #F7FBFE;
  --light-color-transperent: #F7FBFE75;
  --dark-color: #0D160B;
  --dark-color-light: #0D160B15;
  --dark-color-transperent: #0D160B0a;

  /* Pink base theme */
  /* --primary-color: #da4167;
  --primary-color-light: #e7849d;
  --light-color: #fef9fa;
  --light-color-transperent: #fef9fa75;
  --dark-color: #14283e;
  --dark-color-light: #14283e15;
  --dark-color-transperent: #14283e0a; */

  /* Orange base theme */
  /* --primary-color: #cf5c36;
  --primary-color-light: #f5917e;
  --light-color: #ede6e3;
  --light-color-transperent: #ede6e375;
  --dark-color: #4b1f10;
  --dark-color-light: #4b1f1015;
  --dark-color-transperent: #4b1f100a; */

  /* Common theme */

  --red-color: rgb(204, 0, 0);
  --green-color: rgb(0, 128, 0);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.blur *:not(#root, #products-container, .non-blur, .non-blur *) {
  filter: blur(4px);
}

.hidden {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
